import {findPhoneLinksIn} from "./phone-link-utils/phone-link-utils";
import {disassembleBrokenPhoneLinksOnPage} from "./phone-link-utils/disassemble-broken-phone-links-on-page";
import {searchAndAssemblePhonesIntoLinksRight} from "./phone-link-utils/parse-phones";
import {markPhoneLinks} from "./phone-link-utils/mark-phone-links";

export function rebuildCorrectPhoneLinksOnThePage(root: HTMLElement) {
    const dirtyPhoneLinks = findPhoneLinksIn(root);
    disassembleBrokenPhoneLinksOnPage(dirtyPhoneLinks);
    // fixme phone numbers like '<a>044 888-88-88</a>' (not started with +38 - not parsed correctly) is not correctly reassembled (is not marked with .bh-phone-link__phone).
    searchAndAssemblePhonesIntoLinksRight(root);
    const cleanPhoneLinks = findPhoneLinksIn(root);
    markPhoneLinks(cleanPhoneLinks);
    return cleanPhoneLinks;
}
